.title-bar {
  background: #ffffff !important; }

.main-drawer-conatiner > nav {
  width: 272px !important; }

.main-drawer-conatiner > nav > ul > li:nth-child(3) {
  bottom: 2px;
  max-width: 272px;
  padding-bottom: 14px;
  pointer-events: none; }

.text-drawer {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  /* identical to box height */
  letter-spacing: -0.03em;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  white-space: break-spaces; }

.image-drawer {
  text-align-last: center; }

.main-drawer-conatiner > nav > ul > li:nth-child(3) > div.md-list-tile--active {
  background: none !important; }

.main-drawer-conatiner
> nav
> ul
> li:nth-child(3)
> div.md-pointer--hover:hover {
  cursor: initial; }

.vaccine-information-drawer {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .vaccine-information-drawer .vaccine-information-drawer-box {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 80%;
    max-height: 80%;
    padding: 16px; }
    .vaccine-information-drawer .vaccine-information-drawer-box .vaccine-information-box-top {
      width: 24px;
      height: 24px;
      margin-bottom: 12px;
      margin-left: auto;
      cursor: pointer; }

.md-tile-content--left-icon div {
  color: #676767 !important; }

.md-list-item:nth-child(3) {
  background-color: red;
  display: none; }

.md-toolbar-relative li:nth-child(3) {
  display: none; }
