.container-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 12px; }

.brand {
  vertical-align: middle;
  height: 32px; }

.brand-text {
  padding-left: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  color: #262626;
  margin-top: 16px; }

.container-user {
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 1rem; }

.avatar-user {
  background-color: #ffffff;
  vertical-align: middle; }

.container-client-brand {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding-right: 10px; }

.container-client-brand .profile-container {
  flex: 1;
  display: inline-flex; }

.container-client-brand .profile-container a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87); }

.container-client-brand .profile-container .img-container {
  display: flex;
  justify-content: center; }

.container-client-brand .profile-container .img-container img {
  width: 40px; }

.container-client-brand .profile-container .doctor-user {
  padding-left: 10px;
  height: 40px;
  overflow: hidden;
  justify-content: flex-start;
  display: flex;
  flex-direction: column; }

.container-client-brand .profile-container .doctor-user .doctor-user-name {
  font-size: 15px;
  font-weight: 500; }

.container-client-brand .profile-container .doctor-user .doctor-user-title {
  font-size: 12px; }

.container-client-brand .setting-container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end; }

@media screen and (max-width: 380px) {
  .container-client-brand .setting-container .client-name {
    display: none;
    visibility: hidden;
    width: 0px; } }

.container-client-brand .setting-container .client-name .brand-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: #262626;
  width: 180px;
  height: 29px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0px; }

.container-client-brand .setting-container .logout-link {
  text-align: right;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer; }

.container-client-brand .p-15 {
  padding: 15px; }

.container-client-brand .no-mg {
  margin-left: 0px;
  margin-right: 0px; }

.doc-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #000000; }

.doc-details-logout {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #000000; }

.doc-profile-container {
  height: 250px;
  width: 250px;
  display: grid;
  padding: 20px;
  justify-items: center; }

.profile-content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: left;
  color: #000000; }

.profile-btn {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #000000; }

.doctor-profile-detail-container {
  display: flex;
  align-items: center;
  padding: 4px 0; }
  .doctor-profile-detail-container .doctor-profile-detail-arrow {
    width: 12px;
    height: 12px;
    margin-left: 12px; }

.container-brand-right {
  display: flex;
  align-items: center;
  column-gap: 12px; }
  .container-brand-right .container-client-brand-logo {
    display: flex;
    align-items: center;
    justify-content: center; }
    .container-brand-right .container-client-brand-logo img {
      width: 100px;
      height: 52px; }
